<script setup lang="ts">
import { ref } from "vue";
import { useForm } from "vee-validate";
import { z } from "zod";
import { useAxios } from "@vueuse/integrations/useAxios";
import { ClassErrorHandler } from "@/class/ClassErrorHandler";
import { PriceExtend, ModalityExtend, PaymentTypeDTO, DiscountCodeExtend, ProductExtend } from "@/dto";
import UiOrgaModal from "@/ui/OrgaModal.vue";
import BaseTextArea from "@/ui/form-input/BaseTextArea.vue";
import BasePhoneNumber from "@/ui/form-input/BasePhoneNumber.vue";
import BaseDateField from "@/ui/form-input/BaseDateField.vue";

interface ItemRestricted {
  id: number;
  type: string;
  title: string;
  message: string;
}

// Props interface
interface Props {
  prices?: PriceExtend[];
  modalities?: ModalityExtend[];
  paymentTypes?: PaymentTypeDTO[];
  discountCodes?: DiscountCodeExtend[];
  products?: ProductExtend[];
  isTextDisplay?: boolean;
}

// Props with defaults
const props = withDefaults(defineProps<Props>(), {
  prices: () => [],
  modalities: () => [],
  paymentTypes: () => [],
  discountCodes: () => [],
  products: () => [],
  isTextDisplay: false,
});

// Reactive state
const itemsRestricted = ref<ItemRestricted[]>([]);
const selectedItems = ref<ItemRestricted[]>([]);
const modal = ref(false);
const submitLoading = ref(false);
const message = ref("");
const phone = ref("");
const success = ref(false);
const birthdate = ref("");

// Validation schema using Zod
const schema = z.object({
  birthdate: z
    .string()
    .min(1, "La date de naissance est requise.")
    .refine((date) => /\d{4}-\d{2}-\d{2}/.test(date), "Date invalide, format attendu : YYYY-MM-DD"),
  phone: z.string().min(1, "Le numéro de téléphone est requis."),
  selectedItems: z.array(z.object({
    id: z.number(),
    type: z.string(),
    title: z.string(),
    message: z.string(),
  })).min(1, "Veuillez sélectionner au moins un élément."),
  message: z.string().max(800, "Le message ne peut pas dépasser 800 caractères.").optional(),
});

// VeeValidate form setup
const { handleSubmit, values, errors } = useForm({
  validationSchema: schema,
  initialValues: {
    birthdate: "",
    phone: userPhone(),
    selectedItems: [],
    message: "",
  },
});

// Initialize restricted items
function init() {
  createItemsRestrictedFromPrices();
  createItemsRestrictedFromModalities();
  createItemsRestrictedFromPaymentTypes();
  createItemsRestrictedFromDiscounts();
  createItemsRestrictedFromProducts();
  phone.value = userPhone();
  if (itemsRestricted.value.length === 1) selectedItems.value.push(itemsRestricted.value[0]);
}
init();

function createItemsRestrictedFromPrices() {
  props.prices?.forEach((price) => {
    if (price.priceRelationId) {
      itemsRestricted.value.push({
        id: price.priceRelationId,
        type: "price",
        title: `${price.titleClean} - ${price.amountTotal} €`,
        message: price.description,
      });
    }
  });
}

function createItemsRestrictedFromModalities() {
  props.modalities?.forEach((modality) => {
    itemsRestricted.value.push({
      id: modality.id,
      type: "modality",
      title: modality.namePublic,
      message: modality.description,
    });
  });
}

function createItemsRestrictedFromProducts() {
  props.products?.forEach((product) => {
    itemsRestricted.value.push({
      id: product.id,
      type: product.type,
      title: product.title,
      message: "",
    });
  });
}

function createItemsRestrictedFromPaymentTypes() {
  props.paymentTypes?.forEach((paymentType) => {
    itemsRestricted.value.push({
      id: paymentType.id,
      type: "paymentType",
      title: paymentType.title,
      message: paymentType.message || "",
    });
  });
}

function createItemsRestrictedFromDiscounts() {
  props.discountCodes?.forEach((discountCode) => {
    itemsRestricted.value.push({
      id: discountCode.id,
      type: "discount",
      title: `Réduction - ${discountCode.title}`,
      message: discountCode.description,
    });
  });
}

// Save the restriction request
async function save() {
  try {
    submitLoading.value = true;
    await postRestrictionRequest();
    success.value = true;
  } catch (error) {
    const errorUtil = new ClassErrorHandler(error);
    errorUtil.displayErrorIfDev();
  } finally {
    submitLoading.value = false;
  }
}

async function postRestrictionRequest() {
  const urlAxios = "/restriction-request";
  const { execute, data } = useAxios(urlAxios, { method: "PUT" }, { immediate: false });

  await execute({
    data: {
      publicId: getCart().publicId,
      message: values.message,
      birthDate: values.birthdate,
      phone: values.phone,
      items: values.selectedItems,
    },
  });

  if (!data.value || !data.value.success) {
    throw new Error("Aucune donnée enregistrée.");
  }
}

function getCart() {
  return useCartStore().cart;
}

function getOrganizer() {
  return useOrganizerStore().organizer;
}

function userPhone() {
  return useUserStore().user.phone;
}
</script>

<template>
  <div>
    <v-alert
      v-if="itemsRestricted.length > 0"
      type="info"
      border="start"
      elevation="1"
      :color="getOrganizer().colorConfig.shades.standardOrDarkerIfLight"
      colored-border
      class="text-body-2"
    >
      <div v-if="props.isTextDisplay">Les {{ getTitle() }} ci-dessous ne sont pas en libre accès, demandez l'accès :</div>
      <ul class="pl-2">
        <li v-for="(itemRestricted, index) in itemsRestricted" :key="index">
          {{ itemRestricted.title }} {{ itemRestricted.message }}
        </li>
      </ul>
      <div class="text-right">
        <v-btn class="ma-2" outlined :color="getOrganizer().color" @click="modal = true">
          Demander l'accès
        </v-btn>
      </div>
    </v-alert>

    <UiOrgaModal
      :modal="modal"
      :width="'600px'"
      :title-background-color="getOrganizer().color"
      :title-text-color="getOrganizer().colorConfig.textColor"
      @close="modal = false; success = false"
    >
      <template #header> Demande d'accès pour {{ getTitle() }} </template>
      <template #content>
        <div v-if="success" class="text-h5">
          Demande envoyée. On vous répond par email dans quelques jours. Vous pourrez reprendre votre panier à ce moment
          là.
        </div>
        <div v-else>
          <BaseDateField v-model="values.birthdate" :error-messages="errors.birthdate" label="Date de naissance" />
          <BasePhoneNumber v-model="values.phone" :error-messages="errors.phone" label="Téléphone" />
          <BaseTextArea v-model="values.message" :max="800" label="Votre message" :error-messages="errors.message" />
        </div>
      </template>
      <template #footer>
        <v-btn
          v-if="!success"
          type="submit"
          :color="getOrganizer().color"
          :loading="submitLoading"
          :disabled="values.selectedItems.length < 1"
          @click="handleSubmit(save)"
        >
          Demander l'accès
        </v-btn>
      </template>
    </UiOrgaModal>
  </div>
</template>
